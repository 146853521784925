import { styled, Typography } from "@mui/material";
import * as React from "react";
import "../static/typography.css";
import imagen from "../images/LIGAPRIMERA.png";
// styles
const Root = styled("div")(({theme})=>({
  padding: theme.spacing(0),
  margin: theme.spacing(0),
  textAlign: "center",
background: "linear-gradient(90deg, rgba(30,30,83,1) 15%, rgba(18,18,61,1) 100%)",
  backgroundSize: "cover",
  height:"100%",
  flex:1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",


  
  }));

// markup
const IndexPage = () => {
  return (
    <Root>
      <title>Liga Primera</title>
      <Typography variant="h3"
              fontFamily="HouseMovements"
              sx={{ color: "#FFFFFF"}}>
        NUESTRO NUEVO SITIO SE ENCUENTRA EN DESARROLLO
      </Typography>
      <img src={imagen} alt="Liga Primera" width="50%" style={{
        userSelect:"none",
      }} onDragStart={(e) => {
        e.preventDefault();
      }} />
    </Root>
  )
}

export default IndexPage
